<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}listPage?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">公司概况</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}listPage`">公司概况</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div class="listContainer">
              <detail-page ref="detailPage" :gropId="gropId" v-if="pageType == 1"></detail-page>
              <list-page ref="listPage" :gropId="gropId" v-if="pageType == 2"></list-page>
            </div>
            <!-- <div
              class="listContainer"
              v-loading="loading"
              element-loading-text="Loading"
              element-loading-spinner="el-icon-loading"
              v-html="pageCont">
            </div> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import listPage from '@/components/list.vue'
import detailPage from '@/components/detail.vue'
export default {
  name: 'list',
  components: {
    listPage,
    detailPage,
  },
  data(){
    return {
      pageType: 0,   // type 1: 文章；2：列表；3：图片列表；4：视频列表；5：列表-显示描述；
      gropId: '',
      loading: false,
      listId: null,
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '公司概况',
        sub: 'COMPANY',
      },
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
      ],
      page: [
        '<p style="text-indent: 2em; line-height: 26px; margin-bottom: 10px;">公司简介</p><p>公司简介公司简介公司简介</p>',
        '<p>组织机构</p><p>组织机构</p><p>组织机构</p> <p>组织机构</p><p>组织机构</p>',
        '<p>企业文化</p><p>企业文化</p><p>企业文化</p> <p>企业文化</p><p>企业文化</p>',
        '<p>联系我们</p><p>联系我们</p><p>联系我们</p> <p>联系我们</p><p>联系我们</p>',
      ]
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    
    _this.getSideNav()
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    },
    // 获取二级栏目
    async getSideNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "117"});

      data.data.length && data.data.forEach(line => {
        // type 1: 文章；2：列表；3：图片列表；4：视频列表；5：列表-显示描述；
        let dataJson = {
          name: line.name,
          id: line.id,
          type: 1
        }
        
        if (line.have_list == 1) {
          dataJson.type = 1
        } else if (line.have_list == 0) {
          dataJson.type = 2
        }
        _this.slideList.push(dataJson)
      })

      this.$nextTick(() => {
        _this.hdTitle = _this.slideList[_this.active].name
        _this.hdName = _this.slideList[_this.active].name
        _this.listId = _this.slideList[_this.active].id
        _this.gropId = _this.slideList[_this.active].id
        _this.pageType = _this.slideList[_this.active].type
        // _this.getList(_this.slideList[_this.active].id)
      })
    },
    // 查列表
    async getList(id) {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: id, page: 1, pageSize: 10});
      _this.getDetail(data.data.list[0].id)
    },
    // 查详情
    async getDetail(id) {
      var _this = this;

      _this.loading = true
      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.pageCont = data.data.content;
      _this.loading = false
    },
  }
}
</script>
<style scoped>
.day {
  display: inline-block;
}
</style>